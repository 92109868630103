<template>
  <component
    :is="component"
    class="font-icon"
    :style="{
      width: size ? `${size}px` : width ? `${width}px` : 'auto',
      minWidth: size ? `${size}px` : width ? `${width}px` : 'auto',
      height: size ? `${size}px` : height ? `${height}px` : 'auto',
    }"
  />
</template>

<script>
import FaVk from "@/assets/icon/vk-fa.svg?component";
import FaTelegram from "@/assets/icon/telegram.svg?component";
import FaCreditCardFront from "@/assets/icon/credit-card-front.svg?component";
import FaEnvelope from "@/assets/icon/envelope.svg?component";
import FaFrown from "@/assets/icon/face-frown.svg?component";
import FaSmile from "@/assets/icon/face-smile.svg?component";
import FaPhone from "@/assets/icon/phone.svg?component";
import FaWallet from "@/assets/icon/wallet.svg?component";
import IconEmail from "@/assets/icon/email.svg?component";
import IconDay from "@/assets/icon/day.svg?component";
import IconAlert from "@/assets/icon/alert.svg?component";
import IconNight from "@/assets/icon/night.svg?component";
import IconMenu from "@/assets/icon/menu.svg?component";
import IconSearchBtn from "@/assets/icon/search.svg?component";
import IconGift from "@/assets/icon/gift.svg?component";
import IconCasino from "@/assets/icon/casino.svg?component";
import IconCasino05 from "@/assets/icon/casino-05.svg?component";
import IconLive from "@/assets/icon/live.svg?component";
import IconSlots from "@/assets/icon/slots.svg?component";
import IconTrixGames from "@/assets/icon/trix-games.svg?component";
import IconBonusBuy from "@/assets/icon/bonus-buy.svg?component";
import IconBubbles from "@/assets/icon/bubbles.svg?component";
import IconCrazy from "@/assets/icon/crazy.svg?component";
import IconDice from "@/assets/icon/dice.svg?component";
import IconHotline from "@/assets/icon/hotline.svg?component";
import IconMines from "@/assets/icon/mines.svg?component";
import IconPopit from "@/assets/icon/popit.svg?component";
import IconWheel from "@/assets/icon/wheel.svg?component";
import IconX100 from "@/assets/icon/x100.svg?component";
import IconArrow from "@/assets/icon/arrow.svg?component";
import IconShieldAlt from "@/assets/icon/shield-alt.svg?component";
import IconCheck from "@/assets/icon/check.svg?component";
import IconSearch from "@/assets/icon/search2.svg?component";
import IconWallet from "@/assets/icon/wallet2.svg?component";
import IconMinus from "@/assets/icon/minus.svg?component";
import IconPlay from "@/assets/icon/play.svg?component";
import IconBurger from "@/assets/icon/burger.svg?component";
import IconBurgerOn from "@/assets/icon/burger-on.svg?component";
import IconVk from "@/assets/icon/vk.svg?component";
import IconTg from "@/assets/icon/tg.svg?component";
import IconBonusMono from "@/assets/icon/bonus-mono.svg?component";
import IconFaqMono from "@/assets/svg/faq-mono.svg?component";
import IconReferralMono from "@/assets/svg/referral-mono.svg?component";
import IconSupportMono from "@/assets/svg/support-mono.svg?component";
import IconDots from "@/assets/svg/dots.svg?component";
import IconEdit from "@/assets/icon/edit.svg?component";
import IconPlayStroke from "@/assets/icon/play-stroke.svg?component";
import IconShield from "@/assets/icon/shield.svg?component";
import IconCopy from "@/assets/icon/copy.svg?component";
import IconFilter from "@/assets/icon/filter.svg?component";
import IconArrowLong from "@/assets/icon/arrow-long.svg?component";
import IconHistory from "@/assets/icon/history.svg?component";
import IconUsers from "@/assets/icon/users.svg?component";
import IconClock from "@/assets/icon/clock.svg?component";
import IconClose from "@/assets/icon/close.svg?component";
import IconPlus from "@/assets/icon/plus.svg?component";
import IconUser from "@/assets/icon/user.svg?component";
import IconPassword from "@/assets/icon/password.svg?component";
import IconArchive from "@/assets/icon/archive.svg?component";
import IconCup from "@/assets/icon/cup.svg?component";
import Emojy1 from "~/assets/icon/emojy/1.svg";
import Emojy2 from "~/assets/icon/emojy/2.svg";
import Emojy3 from "~/assets/icon/emojy/3.svg";
import Emojy4 from "~/assets/icon/emojy/4.svg";
import Emojy5 from "~/assets/icon/emojy/5.svg";
import Emojy6 from "~/assets/icon/emojy/6.svg";
import Emojy7 from "~/assets/icon/emojy/7.svg";
import Emojy8 from "~/assets/icon/emojy/8.svg";
import BubblesNext from "~/assets/svg/bubbles/anon.svg";
import BubblesEmpty from "~/assets/svg/bubbles/empty.svg";
import Unlock from "~/assets/icon/unlock.svg";
import Undo from "~/assets/icon/undo.svg";
import Show from "~/assets/icon/show.svg";
import Hide from "~/assets/icon/hide.svg";
import Lock from "~/assets/icon/lock.svg";

import IconTower from "@/assets/icon/tower.svg?component";

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  computed: {
    component() {
      switch (this.icon.toLowerCase()) {
        case "vkcolored":
          return FaVk;
        case "email":
          return IconEmail;
        case "user":
          return IconUser;
        case "password":
          return IconPassword;
        case "cup":
          return IconCup;
        case "telegram":
          return FaTelegram;
        case "card":
          return FaCreditCardFront;
        case "envelope":
          return FaEnvelope;
        case "frown":
          return FaFrown;
        case "smile":
          return FaSmile;
        case "phone":
          return FaPhone;
        case "wallet":
          return FaWallet;
        case "day":
          return IconDay;
        case "night":
          return IconNight;
        case "menu":
          return IconMenu;
        case "search2":
          return IconSearchBtn;
        case "search":
          return IconSearch;
        case "gift":
          return IconGift;
        case "casino":
          return IconCasino;
        case "casino05":
          return IconCasino05;
        case "live":
          return IconLive;
        case "slots":
          return IconSlots;
        case "trixgames":
          return IconTrixGames;
        case "close":
          return IconClose;
        case "bonusbuy":
          return IconBonusBuy;
        case "bubbles":
          return IconBubbles;
        case "crazy":
          return IconCrazy;
        case "dice":
          return IconDice;
        case "tower":
          return IconTower;
        case "hotline":
          return IconHotline;
        case "mines":
          return IconMines;
        case "popit":
          return IconPopit;
        case "wheel":
          return IconWheel;
        case "x100":
          return IconX100;
        case "arrow":
          return IconArrow;
        case "shield-alt":
          return IconShieldAlt;
        case "check":
          return IconCheck;
        case "wallet2":
          return IconWallet;
        case "minus":
          return IconMinus;
        case "plus":
          return IconPlus;
        case "burger":
          return IconBurger;
        case "burgeron":
          return IconBurgerOn;
        case "vk":
          return IconVk;
        case "tg":
          return IconTg;
        case "bonus-mono":
          return IconBonusMono;
        case "close":
          return IconClose;
        case "faq-mono":
          return IconFaqMono;
        case "referral-mono":
          return IconReferralMono;
        case "support-mono":
          return IconSupportMono;
        case "dots":
          return IconDots;
        case "edit":
          return IconEdit;
        case "play-stroke":
          return IconPlayStroke;
        case "play":
          return IconPlay;
        case "alert":
          return IconAlert;
        case "shield":
          return IconShield;
        case "copy":
          return IconCopy;
        case "filter":
          return IconFilter;
        case "arrow-long":
          return IconArrowLong;
        case "history":
          return IconHistory;
        case "archive":
          return IconArchive;
        case "users":
          return IconUsers;
        case "clock":
          return IconClock;
        case "emojy-1":
          return Emojy1;
        case "emojy-2":
          return Emojy2;
        case "emojy-3":
          return Emojy3;
        case "emojy-4":
          return Emojy4;
        case "emojy-5":
          return Emojy5;
        case "emojy-6":
          return Emojy6;
        case "emojy-7":
          return Emojy7;
        case "emojy-8":
          return Emojy8;
        case "bubbles-next":
          return BubblesNext;
        case "bubbles-empty":
          return BubblesEmpty;
        case "unlock":
          return Unlock;
        case "lock":
          return Lock;
        case "undo":
          return Undo;
        case "show":
          return Show;
        case "hide":
          return Hide;
      }
    },
  },
};
</script>

<style lang="scss">
.font-icon {
  fill: currentColor;
  width: auto;
  display: inline;
}
</style>
